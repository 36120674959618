
import Vue from "vue";

export default Vue.extend({
	name: "SnackBar",
	model: {
		prop: "model_data",
		event: "input",
	},
	props: {
		model_data: {},
	},
	components: {},
	data: function () {
		return {};
	},
	created() {
		this.$nextTick(async () => {});
	},
	mounted() {},
	computed: {
		modelData: {
			set(val: any) {
				this.$emit("input", val);
			},
			get() {
				return this.model_data;
			},
		},
	},
	methods: {},
	watch: {},
});
