
// @ts-ignore
import { ModifierOptions } from "@/interfaces/modifier";
import Filterable from "../../../../components/Header/Tables/Filterable.vue";
import { SortingOption } from "../../../../interfaces/paginated";
// @ts-ignore
import IconBase from "@/components/Commons/Icons/IconBase.vue";

import { isNull, orderBy } from "lodash";
import Vue from "vue";

export default Vue.extend({
	name: "ModifiersHeader",
	props: {
		current_page: {
			type: Number,
		},
		next_page_url: {
			type: String,
		},
		path: {
			type: String,
		},
		per_page: {
			type: Number,
		},
		prev_page_url: {
			type: String,
		},
		to: {
			type: Number,
		},
		total: {
			type: Number,
		},
		headers: {
			type: Array,
			default: [],
		},
		items: {
			type: Array,
			default: [],
		},
		limit: {
			type: Number,
			default: 25,
		},
		options: {
			type: Object,
			default: function () {
				return {};
			},
		},
		filters: {
			type: Object,
			default: function () {
				return {};
			},
		},
	},
	components: {
		Filterable,
		IconBase,
	},
	data: () => ({
		radios: false,
		filter: {
			id: {
				value: "",
				order: "asc",
			},
			name: {
				value: "",
				order: "asc",
			},
			updateDate: {
				value: "",
				order: "asc",
			},
			typeName: {
				value: "",
				order: "asc",
			},
			advertiserId: {
				value: "",
				order: "asc",
			},
		},
		filtered: [],
	}),

	created() {},

	mounted() {},

	computed: {
		currentPage: {
			set(val) {
				this.$emit("update-current-page", val);
			},
			get() {
				return this.current_page;
			},
		},

		getLength() {
			return Math.ceil(this.total / this.per_page);
		},
	},

	methods: {
		
		getNameAdvertiser(item: any){
			return item.advertiser_name ? item.advertiser_name : "-";
		},

		getColor(active: Boolean) {
			return active ? "green--text" : "red--text";
		},

		getActiveText(active: Boolean) {
			return active ? "Active" : "Inactive";
		},

		updatePaginate(data: Number) {
			this.$emit("update-paginate", data);
		},

		selectedOption(params: { options: SortingOption; filter: any }) {
			this.$emit("selected-option", {
				options: params.options,
				filter: params.filter,
			});
		},

		removeOption() {
			this.$emit("remove-option");
		},
		
		rowClick(item: { id: number }, slot: any, type: any) {
			switch (type) {
				case "line":
					this.$router.push({
						name: "ModifiersIndex"
					});
					event?.stopPropagation();
					break;
				case "row":
					this.$router.push({
						name: "ModifiersEdit",
						params: { id: item.id },
					});
					break;
			}
		},
	},
});
